<template>
    <div v-if="$store.state.isNotLoggedIn === true" class="d-flex flex-column justify-center align-center fill-height">
        <v-card class="elevation-0" style="box-shadow: rgb(180, 180, 202) 0px 0px 20px 2px !important; border-radius: 5px 5px 0 0" max-width="300">
            <v-card-title style="background: rgba(241,241,241,0.8)" class="py-1">
                <div style="width: 100%;" class="d-flex flex-column align-center justify-center">
                    <img class="mt-2" width="58" src="@/assets/N-Nuvolos-blue.svg" />
                    <span class="title pt-2 primary--text">Nuvolos</span>
                </div>
            </v-card-title>
            <v-card-text class="font-weight-light">
                <div class="d-flex flex-wrap justify-center align-center mt-2 black--text" v-if="loginStep === 0">
                    <v-form v-model="valid" @submit="checkLoginType(email)" onSubmit="return false;" style="width:100%" class="mt-6">
                        <v-text-field
                            placeholder="Nuvolos Sign-up Email"
                            class="font-weight-light"
                            id="nv-email"
                            v-model="email"
                            :rules="[rules.nonEmptyUsername]"
                            style="font-size: 13px;"
                            autofocus
                            outlined
                            dense
                        >
                        </v-text-field>
                        <v-alert text type="error" v-if="checkLoginTypeError" class="mt-4">
                            <div class="d-flex flex-column">
                                <span class="font-weight-bold">Error</span>
                                We encountered an error. Please check the system status or reach out to us using the button below.
                                <v-btn light outlined @click="showIntercomChat" class="text-decoration-underline" color="red">Help</v-btn>
                            </div>
                        </v-alert>
                    </v-form>
                </div>
                <div v-if="loginStep === 0">
                    <div class="mb-2 text-center">
                        <a style="text-decoration:none; font-size: 13px" @click="getEmailHelp" class="black--text">Don't remember your sign-up e-mail?</a>
                    </div>
                    <div class="text-center">
                        <a style="text-decoration:none; font-size: 13px" class="black--text" @click="getAccountHelp">Don't have an account?</a>
                    </div>
                    <div v-if="showEmailHelp" class="mt-2">
                        <v-alert type="error" v-if="failedEmail"> No account found for {{ failedEmail }}. </v-alert>
                        <v-alert type="info" class="my-4">
                            Please check the invitation e-mail you received from Nuvolos and use the e-mail address it was sent to.
                        </v-alert>

                        You might be able to log in directly using your institution:
                        <institution-login :redirectTo="nvTarget" class="my-2" />
                        <div class="caption">If you have trouble accessing your account, you can <a @click="showIntercomChat">ask for help</a>.</div>
                    </div>
                    <div v-if="showAccountHelp" class="mt-2">
                        <v-alert type="info">
                            Students accessing Nuvolos require an invitation from their course administrator to create an account.
                        </v-alert>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions class="py-0 px-0">
                <v-btn
                    color="secondary"
                    type="submit"
                    block
                    class="font-weight-light"
                    style="height: 70px;"
                    :disabled="!valid"
                    tile
                    :loading="checkingLoginMethod"
                    @click="checkLoginType(email)"
                    >Next <v-icon>keyboard_arrow_right</v-icon></v-btn
                >
            </v-card-actions>
        </v-card>
    </div>
    <v-container v-else fill-height text-center>
        <div class="d-flex align-center justify-center loadingClass">
            <v-progress-circular indeterminate color="secondary" size="64" width="6"></v-progress-circular>
        </div>
    </v-container>
</template>

<script>
import { defaultAccountType } from '../utils'
import store from 'store'
import { intercomMethods } from '@/mixins/intercom'
const InstitutionLogin = () => import('@/modules/invitations/components/InstitutionLogin.vue')

export default {
    components: { InstitutionLogin },
    mixins: [intercomMethods],
    data() {
        return {
            canShowError: true,
            nvTarget: store.get('nvTarget') || this.$router.resolve({ name: 'home-welcome' }).href,
            email: store.get('nvLastEmail'),
            password: '',
            showpassword: false,
            showSwitchWarning: false,
            switchWarning: 'Based on your email address, please consider logging in with your SWITCH account using the above button.',
            error: '',
            checkLoginTypeError: false,
            rules: {
                nonEmptyPassword: p => p.length > 0 || 'Enter password',
                nonEmptyUsername: v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
            },
            valid: false,
            lastEmail: store.get('nvLoginEmail'),
            loginStep: 0,
            checkingLoginMethod: false,
            loggingIn: false,
            showEmailHelp: false,
            showAccountHelp: false,
            failedEmail: ''
        }
    },
    methods: {
        handleLoginEvent(e) {
            if (e.loggedIn) {
                this.$router.push({ path: this.$data.nvTarget, query: this.$route.query })
                store.set('nvTarget', this.$router.resolve({ name: 'home-welcome' }).href)
            }
        },
        errHandler(err) {
            this.$data.error = err
            this.$data.loggingIn = false
        },
        checkLoginType(email) {
            store.set('nvLastEmail', email)
            this.$data.checkingLoginMethod = true
            this.$axios
                .post('/users/login_methods', { email: email })
                .then(response => {
                    this.$data.checkLoginTypeError = false
                    if (response.data && response.data.methods && response.data.methods.length) {
                        if (response.data.methods[0].toLowerCase() === 'switch') {
                            this.$auth.login({ customState: { target: this.$data.nvTarget }, connection: 'SATOSA', email: email })
                        } else {
                            this.$auth.passwordLogin(email, null)
                        }
                    } else {
                        this.$data.showEmailHelp = true
                        this.$data.failedEmail = email
                    }
                })
                .catch(e => {
                    this.$data.checkLoginTypeError = true
                    this.$data.failedEmail = ''
                })
                .finally(() => {
                    this.$data.checkingLoginMethod = false
                })
        },
        backToEmail() {
            this.$data.loginStep = 0
            this.$data.loggingIn = false
        },
        getEmailHelp() {
            this.$data.showEmailHelp = true
            this.$data.showAccountHelp = false
        },
        getAccountHelp() {
            this.$data.showEmailHelp = false
            this.$data.showAccountHelp = true
        },
        openForgotPassword() {
            window.open('https://id.alphacruncher.net/midpoint/forgotpassword', 'Reset Password', 'width=500,height=500')
            this.$store.dispatch('showSnackBar', { snackBarText: 'You can close the new popup window after the password reset.', snackBarTimeout: 20000 })
        }
    },
    created() {
        if (this.$auth.isAuthenticated()) {
            this.$router.push({ path: this.$data.nvTarget, query: this.$route.query })
            store.set('nvTarget', this.$router.resolve({ name: 'home-welcome' }).href)
        }
    },
    watch: {
        email: function(nextVal, preVal) {
            if (defaultAccountType(preVal) === 'SWITCH' && defaultAccountType(nextVal) !== 'SWITCH' && this.$data.showSwitchWarning) {
                this.$data.showSwitchWarning = false
            }
        }
    }
}
</script>

<style scoped>
.emailSignIn {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin: 10px 0 20px;
}

.emailSignIn span {
    background: #fff;
    padding: 0 5px;
    color: grey;
    font-weight: bold;
}
</style>
